import React from 'react';
import { OmRadioButtonGroup as OmRadioButtonGroupWrapper } from '@om/component-library-react';

const OmRadioButtonGroup = ({
    id,
    onSelectionHandler,
    value,
    groupName,
    vertical = true,
    smallLabel,
    label,
    slot,
    children,
}) => {
    return (
        <OmRadioButtonGroupWrapper
            id={id}
            slot={slot}
            value={value}
            groupName={groupName}
            vertical={vertical}
            label={label}
            smallLabel={smallLabel}
            onOnSelection={(event) => {
                if (onSelectionHandler) {
                    onSelectionHandler(event);
                }
            }}
        >
            <ul slot="list-items">
                {children}
            </ul>
        </OmRadioButtonGroupWrapper>
    );
};

export default OmRadioButtonGroup;
