import React from 'react';
import { graphql } from 'gatsby';
import { OmTableContainer } from '@om/component-library-react';

function getDesktopTableHeaderRow(tableHeaderRow) {

    return tableHeaderRow.row_cells && tableHeaderRow.row_cells.length > 0
        ? {
            rowCells: tableHeaderRow.row_cells.map((rowCell, i) => ({
                slot: generateSlotName('desktop', '', 'header', 1, i + 1),
                slotContent: mapTableCellSlotContent(rowCell),
            }))
        }
        : {};
}

function table_columns_desktop_count(desktop_table_header_rows) {
    let tableHeaderRowDesktop = table_header_row_desktop(desktop_table_header_rows);
    return tableHeaderRowDesktop && tableHeaderRowDesktop.rowCells ? tableHeaderRowDesktop.rowCells.length
        : 0;
}

function table_header_row_desktop(desktop_table_header_row) {
    return desktop_table_header_row
        ? getDesktopTableHeaderRow(desktop_table_header_row)
        : {};
}

function table_header_rows_desktop_count(desktop_table_header_row) {
    return table_header_row_desktop(desktop_table_header_row).rowCells.length > 0 ? 1
        : 0;
}

function getDesktopTableBodyRows(bodyRows) {
    return bodyRows && bodyRows.length > 0
        ? {
            rows: bodyRows.map((row, i) => ({
                rowCells: row.row_cells.map((cell, j) => ({
                    slot: generateSlotName('desktop', '', 'body', i + 1, j + 1),
                    slotContent: mapTableCellSlotContent(cell),
                }))
            }))
        }
        : [];
}

function table_body_row_cells_desktop(desktop_table_body_rows) {
    return table_body_rows_desktop(desktop_table_body_rows).rows && table_body_rows_desktop(desktop_table_body_rows).rows.length > 0
        ? table_body_rows_desktop(desktop_table_body_rows).rows.reduce(
            (accumulator, currentValue) => [...accumulator, ...currentValue.rowCells],
            [])
        : [];
}

function tables_mobile(mobile_tables) {
    return mobile_tables && mobile_tables.length > 0
        ? getMobileTables(mobile_tables)
        : [];
}
function table_header_rows_mobile(mobile_tables) {
    return tables_mobile(mobile_tables).tables && tables_mobile(mobile_tables).tables.length > 0
        ? tables_mobile(mobile_tables).tables.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.headerRows],
            [])
        : [];
}
function table_header_cells_mobile(mobile_tables) {
    return table_header_rows_mobile(mobile_tables) && table_header_rows_mobile(mobile_tables).length > 0
        ? table_header_rows_mobile(mobile_tables).reduce((accumulator, currentValue) => [...accumulator, ...currentValue.cells],
            [])
        : [];
}
function table_body_rows_mobile(mobile_tables) {
    return tables_mobile(mobile_tables) && tables_mobile(mobile_tables).tables && tables_mobile(mobile_tables).tables.length > 0
        ? tables_mobile(mobile_tables).tables.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.bodyRows],
            [])
        : [];
}
function table_body_cells_mobile(mobile_tables) {
    return table_body_rows_mobile(mobile_tables) && table_body_rows_mobile(mobile_tables).length > 0
        ? table_body_rows_mobile(mobile_tables).reduce((accumulator, currentValue) => [...accumulator, ...currentValue.cells],
            [])
        : [];
}
function table_footer_cells_mobile(mobile_tables) {
    return tables_mobile(mobile_tables).tables && tables_mobile(mobile_tables).tables.length > 0
        ? tables_mobile(mobile_tables).tables
            .map(table => table.footerRow.cells && table.footerRow.cells[0] ? table.footerRow.cells[0] : {})
        : [];
}
function table_body_rows_desktop(desktop_table_body_rows) {
    return desktop_table_body_rows && desktop_table_body_rows.length > 0
        ? getDesktopTableBodyRows(desktop_table_body_rows)
        : [];

}
function table_body_rows_desktop_count(desktop_table_body_rows) {
    return table_body_rows_desktop(desktop_table_body_rows).rows && table_body_rows_desktop(desktop_table_body_rows).rows.length > 0 ? table_body_rows_desktop(desktop_table_body_rows).rows.length
        : 0;
}
function tables_mobile_count(mobile_tables) {
    return tables_mobile(mobile_tables).tables ? tables_mobile(mobile_tables).tables.length : 0;
}
function table_header_rows_mobile_count(mobile_tables) {
    // return the mobile table with the largest number of header rows
    // This will be set as the number of header rows for all tables
    const tables = tables_mobile(mobile_tables).tables
        ? tables_mobile(mobile_tables).tables.reduce((prev, current) =>
            (prev.headerRows.length > current.headerRows.length) ? prev : current)
        : 0;

    return tables ? tables.headerRows.length : 0;
}
function table_body_rows_mobile_count(mobile_tables) {

    // return the mobile table with the largest number of body rows
    // This will be set as the number of body rows for all tables
    const tables = tables_mobile(mobile_tables).tables
        ? tables_mobile(mobile_tables).tables.reduce((prev, current) =>
            (prev.bodyRows.length > current.bodyRows.length) ? prev : current)
        : 0;
    return tables ? tables.bodyRows.length : 0;

}
function table_body_columns_mobile_count(mobile_tables) {

    // return the mobile table with the largest number of columns in a body row
    // This will be set as the number of columns in a body row for all tables
    const tableMaxColumnValues = tables_mobile(mobile_tables).tables ?
        tables_mobile(mobile_tables).tables.map(table => ({
            maxColumnsInTable: table.bodyRows && table.bodyRows.length > 0 ?
                table.bodyRows.reduce((prev, current) => (prev.cells.length > current.cells.length) ? prev : current).cells.length :
                0
        }))
        : [];

    return tableMaxColumnValues.length > 0
        ? Math.max(...tableMaxColumnValues.map(table => table.maxColumnsInTable), 0)
        : 0;
}
function table_footer_rows_mobile_count(mobile_tables) {
    // if any of the tables have a footer cell row then return 1
    return tables_mobile(mobile_tables).tables ?
        tables_mobile(mobile_tables).tables.map(table => table.footerRow)
            .filter(footerRow => footerRow.cells && footerRow.cells.length > 0)
            .length > 0 ? 1 : 0
        : 0;
}


function getMobileTables(mobileTables) {
    return mobileTables && mobileTables.length > 0
        ? {
            tables: mobileTables.map((table, i) => ({

                headerRows: table.mobile_table_header_rows.map((row, j) => ({
                    cells: row.row_cells.map((cell, k) => ({
                        slot: generateSlotName('mobile', i + 1, 'header', j + 1, k + 1),
                        slotContent: mapTableCellSlotContent(cell),
                    }))
                })),

                bodyRows: table && table.mobile_table_body_rows ?
                    table.mobile_table_body_rows.map((row, j) => ({
                        cells: row.row_cells.map((cell, k) => ({
                            slot: generateSlotName('mobile', i + 1, 'body', j + 1, k + 1),
                            slotContent: mapTableCellSlotContent(cell),
                        }))
                    })) : {},
                footerRow: table.mobile_table_footer_row && table.mobile_table_footer_row.row_cells ? {
                    cells: table.mobile_table_footer_row.row_cells.map((cell, j) => ({
                        slot: generateSlotName('mobile', i + 1, 'footer', 1, j + 1),
                        slotContent: mapTableCellSlotContent(cell),
                    }))
                } : {},
            }))
        }
        : [];
}
function generateSlotName(formFactor, tableNumber, tableLevel, rowNumber, cellNumber) {

    // eg 'desktop-table-header-row-1-cell-1'
    // eg 'desktop-table-body-row-1-cell-1'
    // eg 'mobile-table-1-header-row-1-cell-1'
    // eg 'mobile-table-1-body-row-1-cell-1'
    // eg 'mobile-table-1-footer-row-1-cell-1'
    return formFactor === 'mobile'
        ? `${formFactor}-table-${tableNumber}-${tableLevel}-row-${rowNumber}-cell-${cellNumber}`
        : `${formFactor}-table-${tableLevel}-row-${rowNumber}-cell-${cellNumber}`
}
function mapTableCellSlotContent(cellContents) {
    const blockContent = cellContents.modular_blocks.map(block => {
        return `${block.table_cell_rich_text ? block.table_cell_rich_text.rich_text : ''}
                ${generateIconCellContent(block.table_cell_icon) || ''}
                ${generateButtonCellContent(block.table_cell_button) || ''}
                ${generateSelectButtonCellContent(block.table_cell_select_button) || ''}`;
    }).join('');

    return blockContent;
}
function generateIconCellContent(iconCellContent) {
    return iconCellContent
        ? iconCellContent.url
            ? `<a href="${iconCellContent.url.href}"><om-icon icon-name="${iconCellContent.icon_name.toLowerCase()}"></om-icon></a>`
            : `<om-icon icon-name="${iconCellContent.icon_name.toLowerCase()}"></om-icon>`
        : '';
}
function generateButtonCellContent(buttonCellContent) {

    return buttonCellContent
        ? `<om-button size="small"
            action-type="${buttonCellContent.button_action_type}"
            type="${normalizeAttributeValue(buttonCellContent.button_type)}"
            url="${buttonCellContent.button_url.href}"
            text="${buttonCellContent.button_text}"
            open-tab="${buttonCellContent.open_in_new_tab}"
            roa-quote-tool-object='${buttonCellContent.roa_quote_tool ? JSON.stringify(buttonCellContent.roa_quote_tool ? buttonCellContent.roa_quote_tool[0] : '') : ''}'
            call-me-back-object='${buttonCellContent.call_me_back ? JSON.stringify(buttonCellContent.call_me_back ? buttonCellContent.call_me_back[0] : '') : ''}'></om-button>`
        : '';
}
function generateSelectButtonCellContent(selectButtonCellContent) {
    return selectButtonCellContent
        ? `<om-button-selector-container class="${selectButtonCellContent.selector_group}">
            <om-button-selector
                slot="${selectButtonCellContent.slot}"
                button-id="${selectButtonCellContent.button_id}"
                selected="${selectButtonCellContent.selected}"
                selector-group="${selectButtonCellContent.selector_group}"
                text="${selectButtonCellContent.button_text}"/>
        </om-button-selector-container>`
        : '';
}
function normalizeAttributeValue(value) {
    return value ? value.split(' ').join('-').toLowerCase() : '';
}


const Table = ({
    tableContent,
    slot,
    bottomButton,
}) => {
    if (!tableContent) {
        return '';
    }
    const {
        uid,
        has_boundary,
        use_heading_as_h1,
        heading,
        caption,
        disclaimer,
        renderKey,
        table_style,
        desktop_table_header_row,
        desktop_table_body_rows,
        mobile_tables,
    } = tableContent;

    return (
        <OmTableContainer
            key={renderKey}
            id={uid}
            slot={slot}
            hasBoundary={has_boundary}
            tablesMobile={tables_mobile_count(mobile_tables)}

            tableColumnsDesktop={table_columns_desktop_count(desktop_table_header_row[0])}
            tableHeaderRowsDesktop={table_header_rows_desktop_count(desktop_table_header_row[0])}
            tableBodyRowsDesktop={table_body_rows_desktop_count(desktop_table_body_rows)}
            tableHeaderRowsMobile={table_header_rows_mobile_count(mobile_tables)}
            tableBodyColumnsMobile={table_body_columns_mobile_count(mobile_tables)}
            tableBodyRowsMobile={table_body_rows_mobile_count(mobile_tables)}
            tableFooterRowsMobile={table_footer_rows_mobile_count(mobile_tables)}
            tableStyle={table_style}
        >
            {
                heading ?
                    (use_heading_as_h1 ?
                        <h1 slot="heading" dangerouslySetInnerHTML={{__html: heading}}/>
                        : <span slot="heading" dangerouslySetInnerHTML={{__html: heading}}/>)
                    : ''
            }

            {
                caption ? <span slot="caption" dangerouslySetInnerHTML={{__html: caption}}/>
                    : ''
            }

            {
                table_header_row_desktop(desktop_table_header_row[0]) ? table_header_row_desktop(desktop_table_header_row[0]).rowCells.map((value, index) => {
                    return <div
                        key={index}
                        slot={value.slot}
                        dangerouslySetInnerHTML={{__html: value.slotContent}}
                    />;
                }) : ''
            }
            {
                table_body_row_cells_desktop(desktop_table_body_rows) ? table_body_row_cells_desktop(desktop_table_body_rows).map((value, index) => {
                    return <div
                        key={index}
                        slot={value.slot}
                        dangerouslySetInnerHTML={{__html: value.slotContent}}
                    />;
                }) : ''
            }
            {
                table_header_cells_mobile(mobile_tables) ? table_header_cells_mobile(mobile_tables).map((value, index) => {
                    return <div
                        key={index}
                        slot={value.slot}
                        dangerouslySetInnerHTML={{__html: value.slotContent}}
                    />;
                }) : ''
            }
            {
                table_body_cells_mobile(mobile_tables) ? table_body_cells_mobile(mobile_tables).map((value, index) => {
                    return <div
                        key={index}
                        slot={value.slot}
                        dangerouslySetInnerHTML={{__html: value.slotContent}}
                    />;
                }) : ''
            }
            {
                table_footer_cells_mobile(mobile_tables) ? table_footer_cells_mobile(mobile_tables).map((value, index) => {
                    return <div
                        key={index}
                        slot={value.slot}
                        dangerouslySetInnerHTML={{__html: value.slotContent}}
                    />;
                }) : ''
            }
            {
                bottomButton && <div slot="bottom-button">{ bottomButton }</div>
            }
            {
                disclaimer ? <div slot="disclaimer" dangerouslySetInnerHTML={{__html: disclaimer}}/>
                    : ''
            }
        </OmTableContainer>
    );
};

export const pageQuery = graphql`
fragment TableRows on Contentstack_table_row {
    uid
    row_cells {
        ...RowCells
    }
}

fragment RowCells on Contentstack_table_cell {
    uid
    modular_blocks {
        id
        table_cell_icon {
            icon_name
            url {
                title
                href
            }
        }
        table_cell_rich_text {
            rich_text
        }
        table_cell_button {
            button_type
            button_action_type
            button_text
            button_url {
                title
                href
            }
            open_in_new_tab
            call_me_back {
                ...CallMeBack
           }
           roa_quote_tool {
            ...RoaQuoteTool
           }
        }
    }
}

fragment MobileTables on Contentstack_mobile_table {
    uid
    mobile_table_header_rows {
        ...TableRows
    }
    mobile_table_body_rows {
        ...TableRows
    }
    mobile_table_footer_row {
        ...TableRows
    }
}

fragment Table on Contentstack_table  {
    id
    uid
    has_boundary
    use_heading_as_h1
    heading
    caption
    disclaimer
    table_style
    desktop_table_header_row {
        ...TableRows
    }
    desktop_table_body_rows {
        ...TableRows
    }
    mobile_tables {
        ...MobileTables
    }
  }

`;

export default Table
