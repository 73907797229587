import React from 'react';
import { graphql } from 'gatsby';
import { OmOneColumnTextParagraph } from '@om/component-library-react';

const Paragraph = ({
    paragraphContent,
    slot
}) => {
    if (!paragraphContent) {
        return '';
    }

    const {
        uid,
        has_boundary,
        text_content
    } = paragraphContent;
    return (
        <OmOneColumnTextParagraph
            id={uid}
            slot={slot}
            hasBoundary={has_boundary}
        >
            <span slot="text-content" dangerouslySetInnerHTML={{ __html: text_content }} />
        </OmOneColumnTextParagraph>
    );
};

export const pageQuery = graphql`
fragment Paragraph on Contentstack_one_column_text_paragraph {
    id
    uid
    has_boundary
    text_content
}
`;

export default Paragraph;
