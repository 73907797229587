import React from 'react';
import { graphql } from 'gatsby';
import { OmArticleCarousel } from '@om/component-library-react';
import RefinedArticleCard from '../components/RefinedArticleCard';

function createSlotName(index) {
    return `card-${index + 1}`;
}

function numberOfCards(om_carousel_slot_article_card) {
    return om_carousel_slot_article_card ? om_carousel_slot_article_card.length : 0;
}

function display_as_landscape_card() {
    return numberOfCards() === 1;
}

const ArticleCarousel = ({
    articleCarouselContent,

}) => {
    if (!articleCarouselContent) {
        return '';
    }
    const {
        uid,
        has_boundary,
        use_heading_as_h1,
        section_heading,
        section_caption,
        om_carousel_slot_article_card,
        button_text,
        button_url,
        button_action_type,
        open_tab,
        call_me_back,
        is_extended_carousel
    } = articleCarouselContent;

    return (
        <OmArticleCarousel
            id={uid}
            hasBoundary={has_boundary}
            buttonText={button_text}
            buttonUrl={button_url.href}
            buttonActionType={button_action_type}
            callMeBackObject={call_me_back ? JSON.stringify(call_me_back[0]) : ''}
            openTab={open_tab}
            numberOfCards={numberOfCards(om_carousel_slot_article_card)}
            isExtended={is_extended_carousel}
        >
            {
                section_heading ?
                    (use_heading_as_h1 ?
                        <h1 slot="section-heading" dangerouslySetInnerHTML={{ __html: section_heading }} />
                        : <span slot="section-heading" dangerouslySetInnerHTML={{ __html: section_heading }} /> )
                    : ''

            }
            {
                <span slot="section-caption" dangerouslySetInnerHTML={{ __html: section_caption }} />
            }
            {
                om_carousel_slot_article_card ? om_carousel_slot_article_card.map((card, index) => {
                    return (
                        <RefinedArticleCard
                            key={card.uid}
                            refinedArticleCardContent={card}
                            isLandscape={display_as_landscape_card()}
                            isExtended={is_extended_carousel}
                            slot={createSlotName(index)}
                        />
                    );
                }) : ''
            }
        </OmArticleCarousel>
    );
};

export const pageQuery = graphql`
fragment ArticleCarousel on Contentstack_carousel_article {
    id
    uid
    has_boundary
    use_heading_as_h1
    section_heading
    section_caption
    om_carousel_slot_article_card: om_carousel_slot {
        ...RefinedArticleCard
    }
    button_text
    button_url {
        ...Link
    }
    button_action_type
    open_tab
    call_me_back {
        ...CallMeBack
    }
    is_extended_carousel
}
`;

export default ArticleCarousel
