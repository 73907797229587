import React from 'react';
import { graphql } from 'gatsby';
import { OmRefinedArticleCard } from '@om/component-library-react';

function getCardTitle(article_card_title) {
    if (article_card_title != null) {
        let returnText = article_card_title;
        if (returnText.length > 500) {
            const validText = returnText.slice(0, 500);
            const lastSpaceIndex = validText.lastIndexOf(' ');
            returnText = validText.slice(0, lastSpaceIndex);
            returnText += '..';
            return returnText;
        } else {
            return returnText;
        }
    }
};

const RefinedArticleCard = ({
    refinedArticleCardContent,
    isLandscape,
    isExtended,
    className,
    slot
}) => {
    if (!refinedArticleCardContent) {
        return '';
    }

    const {
        uid,
        type,
        leader_dob,
        publish_date,
        hide_publish_date,
        image_url,
        article_card_category,
        article_reward_banner,
        article_card_title,
        article_card_copy,
        open_in_new_tab,
        article_url,
    } = refinedArticleCardContent;

    return (
        <OmRefinedArticleCard
            id={uid}
            className={className}
            articleCardCategory={article_card_category}
            articleRewardBanner={article_reward_banner}
            articleCardTitle={getCardTitle(article_card_title)}
            articleCardCopy={article_card_copy}
            articleUrl={article_url ? article_url.href : ''}
            type={type}
            openInNewTab={open_in_new_tab}
            isLanscape={isLandscape}
            isExtended={isExtended}
            imageUrl={image_url ? image_url.url : ''}
            publishDate={publish_date}
            hidePublishDate={hide_publish_date}
            leaderDob={leader_dob}
            slot={slot}
        >

            {
                image_url && <div slot="article-image" style={{backgroundImage: `url('${image_url.url}')`}} />
            }
        </OmRefinedArticleCard>
    );
};

export const pageQuery = graphql`
fragment RefinedArticleCard on Contentstack_refined_article_card {
    uid
    type
    leader_dob
    publish_date
    hide_publish_date
    created_at
    image_url {
        ...File
    }
    article_card_category
    article_reward_banner
    article_card_title
    article_card_copy
    open_in_new_tab
    article_url {
        ...Link
    }
    card_filter_item {
        ...ArticleCardFilterItem
    }
    secondary_filter_items {
        ...ArticleCardFilterItem
    }
}
`;

export default RefinedArticleCard
