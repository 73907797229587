import React from 'react';
import { graphql } from 'gatsby';
import ProTip from './ProTip';
import TwoColumnImageLeft from './TwoColumnImageLeft';
import TwoColumnImageRight from './TwoColumnImageRight';
import ThreeColumnIllustration from './ThreeColumnIllustration';
import Paragraph from './Paragraph'
import QuoteBlock from './QuoteBlock';
import ArticleCarousel from './ArticleCarousel';
import Table from './Table';
import ArticleImage from './ArticleImage';
import OMIFrames from './iFrames';

const ArticlePageModularBlock = ({
    modularBlock,
}) => {
    if(!modularBlock) {
        return '';
    }
    const {
        pro_tip_block,
        three_column_illustration_block,
        paragraph_block,
        quote_block_block,
        article_carousel_block,
        table_block,
        two_column_image_left_block,
        two_column_image_right_block,
        article_image_block,
        iframe_block,
    } = modularBlock;

    if (pro_tip_block != null) {
        return (
            <ProTip proTipContent={pro_tip_block.pro_tip[0]} />
        );
    }
    if (two_column_image_right_block != null) {
        return (
            <TwoColumnImageRight twoColumnImageRightContent={two_column_image_right_block.two_column_image_right[0]} />
        );
    }
    if (two_column_image_left_block != null) {
        return (
            <TwoColumnImageLeft twoColumnImageLeftContent={two_column_image_left_block.two_column_image_left[0]} />
        );
    }
    if (article_image_block != null) {
        return (
            <ArticleImage articleImageContent={article_image_block.article_image[0]} />
        )
    }

    // Add modular blocks here
    if (three_column_illustration_block != null) {
        return (
            <ThreeColumnIllustration threeColumnIllustrationContent={three_column_illustration_block.three_column_illustration[0]} />
        );
    }

    if (paragraph_block != null) {
        return (
            <Paragraph paragraphContent={paragraph_block.paragraph[0]} />
        );
    }

    if(quote_block_block != null) {
        return (
            <QuoteBlock quoteBlockContent={quote_block_block.quote_block[0]} />
        );
    }

    if(article_carousel_block != null) {
        return (
            <ArticleCarousel articleCarouselContent={article_carousel_block.article_carousel[0]} />
        )
    }

    if(table_block != null) {
        return (
            <Table tableContent={table_block.table[0] } />
        )
    }

    if(iframe_block != null) {
        return (
            <OMIFrames iFramesContent={iframe_block.iframe[0]} />
        )
    }

    return '';
};

export const pageQuery = graphql`
fragment ArticlePageModularBlock on Contentstack_modular_article_page_modular_blocks {
    id
    pro_tip_block {
        pro_tip {
            ...ProTip
        }
    }
    two_column_image_right_block {
        two_column_image_right {
            ...TwoColumnImageRight
        }
    }
    three_column_illustration_block {
        three_column_illustration {
            ...ThreeColumnIllustration
        }
    }
    paragraph_block {
        paragraph {
            ...Paragraph
        }
    }
    quote_block_block {
        quote_block {
            ...QuoteBlock
        }
    }
    article_carousel_block {
        article_carousel {
            ...ArticleCarousel
        }
    }
    table_block {
        table {
            ...Table
        }
    }
    two_column_image_left_block {
        two_column_image_left {
            ...TwoColumnImageLeft
        }
    }
    article_image_block{
        article_image {
            ...ArticleImage
        }
    }
    iframe_block {
        iframe {
            ...IFrame,
            ...TabsIFrame
        }
    }

    # Add modular blocks here
}
`;

export default ArticlePageModularBlock;
