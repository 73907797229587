import React from 'react';
import {graphql} from 'gatsby';

import WebComponentsConfig from '../components/WebComponentsConfig';
import MainLayout from '../layouts/MainLayout';
import ArticlePageModularBlock from '../components/ArticlePageModularBlock';
import ArticleLandingHeader from '../components/header/ArticleLandingHeader';
import ArticleVideoContentWrapper from '../components/ArticleVideoContentWrapper';
import Breadcrumbs from '../components/Breadcrumbs';
import StickyFooter from '../components/StickyFooter';
import {
    OmPage,
    OmWebShareLinks,
    Om1ColLayout,
} from '@om/component-library-react';

const ModularArticlePage = ({data, location}) => {
    const {
        uid,
        locale,
        page_head_tags,
        footer,
        modular_blocks,
        article_landing_header,
        page_background_url,
        article_video_content_wrapper,
        floating_footer_bar,
        signup_and_download,
        theme,
        include_web_share,
        disclaimer_text,
    } = data.contentstackModularArticlePage;

    return (
        <WebComponentsConfig themeContent={theme}>
            <OmPage>
                <div slot="page-content">
                    <MainLayout
                        metaContent={page_head_tags}
                        header={
                            <ArticleLandingHeader
                                articleLandingHeaderContent={article_landing_header[0]}
                                background={page_background_url ? page_background_url.url : ''}
                                location={location}
                            />
                        }
                        footerContent={footer && footer[0]}
                        signupContent={signup_and_download && signup_and_download[0]}
                        contentType="modular_article_page"
                        entryUid={uid}
                        locale={locale}
                        location={location}>
                        <ArticleVideoContentWrapper
                            articleVideoContentWrapperContent={article_video_content_wrapper[0]}
                            location={location}
                        />
                        <Breadcrumbs location={location} />
                        {modular_blocks && modular_blocks.map(value =>
                            <ArticlePageModularBlock key={value.id} modularBlock={value}/>
                        )}
                        {
                            include_web_share &&
                                <Om1ColLayout
                                    className="block extra-large-margin-top extra-large-margin-bottom"
                                    narrow={true}
                                >
                                    <OmWebShareLinks />
                                </Om1ColLayout>
                        }
                        {
                            disclaimer_text &&
                                <Om1ColLayout
                                    className="block extra-large-margin-top extra-large-margin-bottom"
                                    narrow={true}
                                >
                                    <p className="no-margin muted" dangerouslySetInnerHTML={{ __html: disclaimer_text }} />
                                </Om1ColLayout>
                        }
                        {
                            floating_footer_bar && <StickyFooter stickyFooterContent={floating_footer_bar[0]}/>
                        }
                    </MainLayout>
                </div>
            </OmPage>
        </WebComponentsConfig>
    );
};

export const pageQuery = graphql`
query ModularArticlePageQuery($id: String!) {
    contentstackModularArticlePage(id: { eq: $id }) {
        id
        uid
        locale
        theme {
            ...Theme
        }
        title
        article_landing_header {
            ...ArticleLandingHeader
        }
        article_video_content_wrapper {
            ...ArticleVideoContentWrapper
        }
        page_background_url {
            ...File
        }
        modular_blocks {
            ...ArticlePageModularBlock
        }
        floating_footer_bar {
            ...FloatingFooterBar,
            ...LiveChat
        }
        footer {
            __typename
            ...Footer,
            ...FooterV2
        }
        page_head_tags {
            ...PageHeadTags
        }
        signup_and_download {
            ...SignupAndDownload
        }
        include_web_share
        disclaimer_text
    }
}
`;

export default ModularArticlePage;
